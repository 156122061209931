import React, { Component } from "react";
import API from "../classes/API.js";
import parse from "html-react-parser";

class Banner extends Component {
  constructor() {
    super();
    this.state = {
      text: "",
      url: undefined,
      hex: "",
      valid: false,
    };
  }

  componentDidMount() {
    this.fetch_banner();
    this.start_timer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      localStorage.setItem(
        "banner",
        JSON.stringify({
          text: this.state.text,
          url: this.state.url,
          hex: this.state.hex,
        })
      );
    }
  }

  start_timer() {
    setTimeout(() => this.fetch_banner(), 60000)
  }

  fetch_banner() {
    let localBanner = JSON.parse(localStorage.getItem("banner"));
    if (localBanner && localBanner.text && localBanner.url && localBanner.hex) {
      this.setState({
        text: localBanner.text,
        url: localBanner.url,
        hex: localBanner.hex,
        valid: true,
      });
    }
    var api = new API();
    let tutorCount = JSON.parse(localStorage.getItem("user"));
    let isTutor = 0;
    if (tutorCount && tutorCount.tutor_count > 0) isTutor = 1;
    api.get(`api/banners/${isTutor}/`, (r) => {
      if (r && r.status === "ok") {
        this.setState({
          text: decodeURIComponent(escape(r.banner.text)),
          url: r.banner.url,
          hex: r.banner.hex,
          valid: true,
        });
      }
    });
  }

  render() {
    if (window.location.pathname === "/login") return "";
    return this.state.valid ? (
      <div>
        {this.state.url ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={this.state.url}
            className="banner"
            style={{ backgroundColor: this.state.hex }}
          >
            {" "}
            {parse(this.state.text.replace("Â", ""))}
          </a>
        ) : (
          <div className="banner" style={{ backgroundColor: this.state.hex }}>
            {" "}
            {parse(this.state.text.replace("Â", ""))}
          </div>
        )}
      </div>
    ) : null;
  }
}

export default Banner;
