import React, { Component } from 'react';
// import './Header.css';
import { RequireLogin } from './Login.js';

import API from '../classes/API.js';

class Payment extends Component {

  constructor(props) {
    super(props)

    this.state = {
      dd_date: '',
      dd_href: '',
      iframe_key: 0
    }

    this.mounted = false;
  }

  componentWillMount() {
    this.mounted = true;
    var p_dd_date = localStorage.getItem('payment_dd_date');

    if (p_dd_date)
      this.setState({ dd_date: p_dd_date, dd_href: 'https://online.smartdebit.co.uk/signuppayer/iframe?pslid=sussexacad&start_date=' + p_dd_date, iframe_key: this.state.iframe_key+1 });

    var api = new API();

    var that = this;
    api.get('api/payment/dd_date', d => {
      if (d.status && d.status === 'ok') {
        if (that.mounted) {
          that.setState({ iframe_key: this.state.iframe_key+1, 'dd_date': d.dd_date, 'dd_href': 'https://online.smartdebit.co.uk/signuppayer/iframe?pslid=sussexacad&start_date=' + d.dd_date });
        }


        localStorage.setItem('payment_dd_date', d.dd_date);
      } else {
        window.location.href = "/404";
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <div className="resources">
    	<RequireLogin />
      	<h2>Payment</h2>
      	<h3>DIRECT DEBIT:</h3>
      	<p>Direct Debit is the main form of payment for all lessons, if you have not already done so, you can create a Direct Debit Instruction by completing the form below. You will receive a confirmation email once setup. Payments are collected one week before the start of each half-term. Direct Debit collection dates are available as a PDF within the resources section.</p>
      	<iframe title="Direct Debit" key={this.state.iframe_key} src={this.state.dd_href} width="100%" height="800"></iframe>
      </div>
    );
  }
}

export default Payment;
