import React, { Component } from 'react';
// import './Header.css';
import { RequireLogin } from './Login.js';
import TutorReady from './TutorReady'

import LessonList from './LessonList.js';

class UpcomingLessons extends Component {
	constructor(params) {
		super(params);

		this.page = 1;
		if(params.match.params && params.match.params.page)
			this.page = params.match.params.page;
	}
  render() {
    return (
      <div className="upcoming">
    	<RequireLogin />
      <TutorReady />
      	<h2>Upcoming Lessons</h2>
      	<LessonList type="1" report="0" page={this.page} history={this.props.history} />
      </div>
    );
  }
}

export default UpcomingLessons;
