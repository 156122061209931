import React, { useState, useEffect } from "react";
import API from "../classes/API.js";
import QuillTextbox from "./QuillTextbox";
import './LessonComment.css'

const LessonComment = (props) => {
  var emptyQuillString = "<p><br></p>"
  const [commentData, setCommentData] = useState({});

  useEffect(() => {
    let options = {}
    var api = new API();
    api.get("api/commentoptions", (response) => {
      Object.values(response.options).forEach(option => {
        options[option.commentType_id] = option
        })
      setCommentData({...commentData, options})
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const onChange = (event) => {
    var value;
    event.target.type !== "checkbox" && event.target.value === emptyQuillString ? value = "" : value = event.target.value

    setCommentData({
      ...commentData,
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : value,
    });
    props.onChange({
      ...commentData,
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : value,
    });
  };

  const renderCommentOptions = () => {
    return commentData.options && Object.values(commentData.options).length > 0
      ? Object.entries(commentData.options).map(([key, value]) => <option value={key}>{value.name}</option>)
      : null;
  };

  return (
    <div className="lessonComment_container">
      <input
        type="checkbox"
        id="leaveComment"
        checked={commentData["leaveComment"]}
        onChange={onChange}
        name="leaveComment"
      /> <label for="leaveComment">Add a comment about this lesson for the SAM admin team</label>
          {commentData.leaveComment ?
        <div>
          <label for="selectedOption">Comment Type </label>
      <select className="lessonComment_element" name="selectedOption" id="selectedOption" onChange={onChange}>
        <option value="" disabled selected>Select Type</option>
        {renderCommentOptions()}
        </select>
          <div className="lessonComment_description">{commentData.selectedOption ? commentData.options[commentData.selectedOption].description : null}</div>
          <QuillTextbox handleChange={onChange} name="comment" /></div> : null }
    </div>
  );
};

export default LessonComment;
