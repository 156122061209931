import React, { Component } from 'react';
import './DashItems.css';
import API from '../classes/API.js';

import { Link } from 'react-router-dom';

class UpcomingDash extends Component {
  render() {
    return (
      <Link to="/upcoming" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/UpcomingLessons.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Upcoming Lessons:
            <p>Click here to view your upcoming lessons, or for your lesson link (if online), available on the day of the lesson</p>
          </div>
        </li>
      </Link>
    );
  }
}

class PreviousDash extends Component {
  render() {
    return (
      <Link to="/previous" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/PreviousLessons.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Previous Lessons:
            <p>View your previous lessons and their reports</p>
          </div>
        </li>
      </Link>
    );
  }
}

class ReportsAndLessonsDash extends Component {
  render() {
    return (
      <Link to="/reports" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/LessonReportsRecordings.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Lesson Recordings + Reports:
            <p>
              Stream / download your lesson (if online) and read / download the tutors report<br></br>
              <i>(Please allow 2 hours for recordings to upload and 24 hours for the report)</i><br></br>
              Weekly and termly summary reports available for online and in-person lessons. <br></br>
              Recordings are available for 7 days after the lesson (if online)
            </p>
          </div>
        </li>
      </Link>
    );
  }
}

class ResourcesDash extends Component {
  render() {
    return (
      <Link to="/resources" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/icons/resources.png" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Resources:
            <p>Use this section to access the resources.</p>
          </div>
        </li>
      </Link>
    );
  }
}

class ReportDash extends Component {
  render() {
    return (
      <Link to="/report" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/SubmitReport.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Submit Lesson Report:
            <p>Submit your report here - you can also access the students practice folder from here to add resources</p>
          </div>
        </li>
      </Link>
    );
  }
}

class PracticeFolderDash extends Component {
  constructor(props) {
    super(props)
    this.state = {
      practice_folders: undefined,
    }
  }

  componentDidMount() {
    this.getDocs()
  }
  componentDidUpdate() {
      if(this.state.practice_folders === undefined) {
        this.getDocs()
      }
  }
  getDocs = () => {
    if(localStorage.getItem("user")) {
    let customers = JSON.parse(localStorage.getItem("user")).customers
    if(!customers) return

    let api = new API()
      api.post('api/find', {customers}, response => {
      if(response.practicefolders) {
      this.setState({'practice_folders': response.practicefolders})
      }
    })
    }
  }

  folders_display = () => {
    let folders = []
    if(this.state.practice_folders) {
      Object.keys(this.state.practice_folders).forEach(customer => {
        let customer_display_name = customer.split("$&^")[0]
        Object.keys(this.state.practice_folders[customer]).forEach(instrument => {
          folders.push(<a className="practice_folder" target="_blank" rel="noopener noreferrer" href={this.state.practice_folders[customer][instrument].substring(0, 4) === 'http' ? this.state.practice_folders[customer][instrument] : "https://" + this.state.practice_folders[customer][instrument]}>{customer_display_name + ": " + instrument}</a> )
        })
      })
    }
    return folders
  }

  render() {
    return (
      <div className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/PracticeFolder.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            <span style={{color:'#FF9900'}}>Practice Folder(s):</span>
            <p>If your tutor has added resources from your lesson, this is where they will be hosted</p>
              {this.folders_display()}
          </div>
        </li>
      </div>
    );
  }
}

class StudentPromotionsDash extends Component {
  render() {
    return (
      <a href="https://www.sammusicservice.com/student-promotions/" target="_blank" rel="noopener noreferrer" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/StudentPromotions.svg" />
          </div>
         <div id="content_container_dash_item" className="float_left">
            Student Promotions:
            <p>Bulk booking discounts, refer a friend, additional lesson discounts and more</p>
          </div>
        </li>
      </a>
    )
  }
 }

class TutorBlogDash extends Component {
  render() {
    return (
      <a href="https://tutorblog.sammusicservice.com" target="_blank" rel="noopener noreferrer" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/Blog.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Tutor Blog:
            <p>Keep up to date with useful information from SAM</p>
          </div>
        </li>
      </a>
    );
  }
}

class ParentBlogDash extends Component {
  render() {
    return (
      <a href="https://www.sammusicservice.com/parent-blog" target="_blank" rel="noopener noreferrer" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/Blog.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Parent Blog:
            <p>Keep up to date with useful information from SAM</p>
          </div>
        </li>
      </a>
    );
  }
}

class GradeAndBooksDash extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gradeinfo_books: undefined,
    }
  }

  componentDidMount() {
    this.getDocs()
  }
  componentDidUpdate() {
      if(this.state.gradeinfo_books === undefined) {
        this.getDocs()
      }
  }
  getDocs = () => {
    if(localStorage.getItem("user")) {
    let email = JSON.parse(localStorage.getItem("user")).tutors[0]
    let api = new API()
    api.get('api/find/' + email, response => {
      if(response.gradeinfobooks) {
      this.setState({'gradeinfo_books': response.gradeinfobooks})
      }
    })
    }
  }

  render() {
    return (
      <a href="https://docs.google.com/spreadsheets/d/1TfEeEnp3cYQq47EWyeFo44gTRq5c4C_Ix26SUAm-2FY/edit#gid=1102362155" target="_blank" rel="noopener noreferrer" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/Grades.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Grade Resources:
            <p>A guide to all popular grade syllabuses, including helpful information and links. Please contact the syllabus or SAM if you are unsure of anything grade related.</p>
          </div>
        </li>
      </a>
    );
  }
}

class ParentGradeSyllabus extends Component {
  render() {
    return (
      <a href="https://docs.google.com/spreadsheets/d/1ixybN9y-n5YWRKgv7ZVaRiXhyL_XMolqgJUaUesJOFk/edit#gid=1102362155" target="_blank" rel="noopener noreferrer" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/Grades.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Grade Resources:
            <p>A guide to all popular grade syllabuses, including helpful information and links. Please contact the syllabus or SAM if you are unsure of anything grade related.</p>
          </div>
        </li>
      </a>
    );
  }
}

class PaymentDash extends Component {
  render() {
    return (
      <Link to="/payment" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/icons/pound.png" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Payment:
            <p>This is where you can set up a Direct Debit if you haven't completed one over the phone.</p>
          </div>
        </li>
      </Link>
    );
  }
}

class GdprDash extends Component {
  render() {
    return (
      <Link to="/gdpr" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/icons/download.png" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            GDPR:
            <p>Use this section to find out about our GDPR compliance policies.</p>
          </div>
        </li>
      </Link>
      )
  }
}

class ShopDash extends Component {
  render() {
    return (
      <a href="https://shop.ucanplay.org.uk/collections/sam" target="_blank" rel="noopener noreferrer" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/Shop.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Instrument Shop:
            <p>A SAM recommended selection of instruments and accessories</p>
          </div>
        </li>
      </a>
    )
  }
}

class CustomerResourcesDash extends Component {
  render() {
    return (
      <a href="https://drive.google.com/drive/folders/1Aq7ITiW9YoXmPZtQ9RQKsRooZYee-dWi" target="_blank" rel="noopener noreferrer" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/Resources+UsefulInfo.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Resources + Useful Info:
            <p>Lesson resources, term-dates, policies and more</p>
          </div>
        </li>
      </a>
    )
  }
}

class TutorResourcesDash extends Component {
  render() {
    return (
      <a href="https://drive.google.com/drive/folders/18f1piZgVnCH7iZXoJb4tKW6B45itoXst" target="_blank" rel="noopener noreferrer" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/Resources+UsefulInfo.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Resources + Useful Info:
            <p>Lesson resources, term-dates, policies and more</p>
          </div>
        </li>
      </a>
    )
  }
}

class LocationResourcesDash extends Component {
  render() {
    return (
      <a href="https://drive.google.com/drive/folders/1zvGzR3jMwIY1DkMpFm7qtOSQqRPbHekm" target="_blank" rel="noopener noreferrer" className="dash__link clearfix">
        <li className="dash_item clearfix">
          <div id="img_container_dash_item" className="float_left">
            <img alt="" src="/images/modern_icons/Resources+UsefulInfo.svg" />
          </div>
          <div id="content_container_dash_item" className="float_left">
            Useful Info:
            <p>Term-dates, policies and more</p>
          </div>
        </li>
      </a>
    )
  }
}

export { UpcomingDash, PreviousDash, ReportDash, PaymentDash, GdprDash, ShopDash, CustomerResourcesDash, TutorResourcesDash, LocationResourcesDash, ParentBlogDash, TutorBlogDash, GradeAndBooksDash, ReportsAndLessonsDash, PracticeFolderDash, StudentPromotionsDash, ResourcesDash, ParentGradeSyllabus };
