import React, { Component } from 'react';
import './LessonWidget.css';
import { RequireLogin } from './Login.js';

import { Link } from 'react-router-dom';
import API from '../classes/API.js';


class LessonWidget extends Component {

  constructor() {
    super();

    this.mounted = false;

    this.state = {
      lesson: {}
    };
  }

  componentWillMount() {
    this.mounted = true;

    var lessons = JSON.parse(localStorage.getItem('upcomingLessons'));

    var next_lesson = {};

    if(lessons) {
      next_lesson = lessons[0];
    }

    this.setState({ lesson: next_lesson });

    var that = this;
    var api = new API();
    api.get('api/lessons/upcoming', d => {
      if(d.status && d.status === 'ok') {
        if(that.mounted && d.lessons.length > 0)
          that.setState({ lesson: d.lessons[0] });

        localStorage.setItem('upcomingLessons', JSON.stringify(d.lessons));
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }


  render() {
    var lesson = <p>You have no lessons booked yet.</p>;
    if(this.state.lesson && this.state.lesson.customers !== undefined) {
      lesson = <ul className="lesson_widget__details">
          <li><b>Student:</b><br /> {this.state.lesson.customers.join(' & ')}</li>
          <li><b>Tutor:</b><br /> {this.state.lesson.tutor}</li>
          <li><b>Instrument:</b><br /> {this.state.lesson.instrument}</li>
          <li><b>Location:</b><br /> {this.state.lesson.location} <br /> {this.state.lesson.room}</li>
          <li><b>When:</b><br /> {this.state.lesson.when}</li>
        </ul>;
    }

    return (
      <div className="lesson_widget float_left">
        <RequireLogin />
        <h3 className="lesson_widget__title">Your Next Lesson:</h3>
        {lesson}
        <hr className="lesson_widget__divider" />
        <Link to="/upcoming">All upcoming lessons</Link>
      </div>
    );
  }
}

export default LessonWidget;
