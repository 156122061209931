import React, { Component } from 'react';
// import './LessonList.css';

import DateTimePicker from 'react-datetime-picker';

import API from '../classes/API.js';

class InvoiceList extends Component {
	constructor(props) {
		super(props);

    var showall = true;
    var page = 1;
    if(props.page && parseInt(props.page))
      page = props.page;
    else if(props.page && props.page === 'all')
      showall = true;

    this.per_page = 10;

    var curr_date = new Date();

    var start_date = new Date(curr_date.getFullYear(), curr_date.getMonth() - 1, 1);
    var end_date = new Date(curr_date.getFullYear(), curr_date.getMonth(), 1);

		this.state = {
			lessons: [],
      start: start_date,
      end: end_date,
      total_hours: 0,
      invoice_hours: 0,
      invoice_amount: 0,
      payperiods: [],
      page: page,
      showall: showall,
      past: "/" + (page > 1 ? (page-1) : page),
      next: "/" + (page + 1),
      all: "/all",
      loading: false
		}

    this.showReport = true;
    this.mounted = false;

		this.getLessons = this.getLessons.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.showall = this.showall.bind(this);
    this.viewLesson = this.viewLesson.bind(this);

    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
	}

  componentWillUnmount() {
    this.mounted = false;
  }

	getLessons(page = 1, showall=false, start=null, end=null) {

    var relation = 'Invoice';

    if(!(this.state.start || start) || !(this.state.end || end)) {
      return;
    }

    var start_json = (start !== null ? start.toJSON() : this.state.start.toJSON());
    var end_json = (end !== null ? end.toJSON() : this.state.end.toJSON());

    var l = JSON.parse(localStorage.getItem(relation+'Lessons'+start_json+'_'+end_json));
    if(l)
      this.setState({ lessons: l });

    var that = this;
    var api = new API();
    this.setState({ 'loading': true });
    api.get('api/lessons/invoice/'+start_json+'/'+end_json, d => {
      if(that.mounted)
        that.setState({'loading': false});

      if(d.status && d.status === 'ok') {
        if(that.mounted)
          that.setState({ lessons: d.lessons, total_hours: d.total_hours, invoice_hours: d.invoice_hours, invoice_amount: d.invoice_amount, payperiods: d.payperiods });

        localStorage.setItem(relation+'Lessons'+start_json+'_'+end_json, JSON.stringify(d.lessons));
      }
    });
	}

  prevPage(e) {
    e.preventDefault();
    var p = parseInt(this.state.page) - 1;
    if(this.state.showall)
      p = 1;
    this.setState({ showall: false });
    var max_p = Math.ceil(this.state.lessons.length / this.per_page);
    if(p > 0 && p <= max_p) {
      this.setState({ page: p });
      this.props.history.push('/' + this.state.relation + '/' + p);
    }

    return false;
  }

  nextPage(e) {
    e.preventDefault();
    var p = parseInt(this.state.page) + 1;
    if(this.state.showall)
      p = 1;
    this.setState({ showall: false });
    var max_p = Math.ceil(this.state.lessons.length / this.per_page);
    if(p > 0 && p <= max_p) {
      this.setState({ page: p });
      this.props.history.push('/' + this.state.relation + '/' + p);
    }

    return false;
  }

  showall(e) {
    e.preventDefault();
    this.setState({ showall: true, page: 1 });
    this.props.history.push('/' + this.state.relation + '/all');
    return false;
  }

  viewLesson(e) {
    var lessonkey = e.currentTarget.attributes["data-key"].value;

    this.props.history.push('/lesson/' + lessonkey);

    return false;
  }


  handleStartChange(d) {
    this.setState({ 'start': d });
    this.getLessons(this.state.page, this.state.showall, d);
  }

  handleEndChange(d) {
    this.setState({ 'end': d });
    this.getLessons(this.state.page, this.state.showall, null, d);
  }

  render() {
  	var rows = [];
    var header;
    var headerLength = 0;

    header = <tr>
          <td>Student</td>
          <td>Tutor</td>
          <td>Location</td>
          <td>Instrument</td>
          <td>When</td>
        </tr>;
    headerLength = 5;

    var period_amounts = [];
    if(this.state.payperiods) {
      for(let i=0; i < this.state.payperiods.length; i++) {
        period_amounts.push(<tr key={i}>
            <td>&pound;{this.state.payperiods[i].rate}</td>
            <td>{this.state.payperiods[i].valid}</td>
            <td>&pound;{this.state.payperiods[i].total}</td>
          </tr>);
      }
    }

    var start = (this.state.showall) ? 0 : (this.state.page - 1) * this.per_page;
    var toshow = [];
    if(this.state.showall) {
      toshow = this.state.lessons;
    } else {
      toshow = this.state.lessons.slice(start, start+this.per_page);
    }

    if(toshow && toshow.length > 0) {
    	for(let i = 0; i < toshow.length; i++) {
          rows.push(<tr key={toshow[i].key} data-key={toshow[i].key} onClick={this.viewLesson}>
            <td>{toshow[i].customers.join(' + ')} <span className="lessonlist__mobileonly"><br />({toshow[i].instrument})</span></td>
            <td>{toshow[i].tutor}</td>
            <td>{toshow[i].location}<br />{toshow[i].room}</td>
            <td>{toshow[i].instrument}</td>
            <td>{toshow[i].when}</td>
            </tr>);
      }
  	} else {
      rows.push(<tr className="lessonlist--empty" key={0}><td colSpan={headerLength}>No lessons found</td></tr>);
    }

    var spinner;
    if(this.state.loading) {
      var api = new API();
      spinner = api.getSpinner();
    }

    return (<div>
      <h2>Invoicing</h2>
      <p>
        Please set the date from the 1st of both months to include the entire month.<br />
        For example; if you are invoicing for January, you will need to select the 1st of January to the 1st of February.<br />
        Please include any discrepancies in the email when you send your invoice, even if you have previously discussed them.<br />
      </p>
      <form>
        <DateTimePicker name="start" onChange={this.handleStartChange} value={this.state.start} /> - <DateTimePicker name="end" onChange={this.handleEndChange} value={this.state.end} />
      </form>
      <br />
      <div>
        {spinner}
        <table className="lessonlist">
          <thead className="lessonlist--head lessonlist--head-align">
            <tr>
              <td>Total Hours</td>
              <td>Invoice Hours</td>
              <td>Invoice Amount</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.total_hours}</td>
              <td>{this.state.invoice_hours}</td>
              <td>&pound;{this.state.invoice_amount}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div className="lessonlist__wrapper">
      	<table className="lessonlist">
      		<thead className="lessonlist--head">
            {header}
      		</thead>
      		<tbody>
      			{rows}
      		</tbody>
      	</table>
      </div>
      <br />
      <div>
        <table className="lessonlist">
          <thead className="lessonlist--head lessonlist--head-align">
            <tr>
              <td>Total Hours</td>
              <td>Invoice Hours</td>
              <td>Invoice Amount</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.total_hours}</td>
              <td>{this.state.invoice_hours}</td>
              <td>&pound;{this.state.invoice_amount}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table className="lessonlist">
          <thead className="lessonlist--head lessonlist--head-align">
            <tr>
              <td>Pay Rate</td>
              <td>Invoice Hours</td>
              <td>Invoice Amount</td>
            </tr>
          </thead>
          <tbody>
            {period_amounts}
          </tbody>
        </table>
      </div>
    </div>);
  }

  componentWillMount () {
    this.mounted = true;

  	this.getLessons(this.state.page, this.state.showall);
  }
}

export default InvoiceList;
