import React, { Component } from 'react';
import './Footer.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';

class Footer extends Component {
  render() {
    return (
      <div className="footer-container">
      <footer className="footer">
        <a href="https://www.facebook.com/sammusicservice" rel="noopener noreferrer" target="_blank"><FacebookIcon fontSize="large"/></a>
        <a href="https://twitter.com/SAMMusicService" rel="noopener noreferrer" target="_blank"><TwitterIcon fontSize="large"/></a>
        <a href="https://www.youtube.com/channel/UCy6-iaEuRnnLTWlA7CAAC5g" rel="noopener noreferrer" target="_blank"><YouTubeIcon fontSize="large"/></a>
        <a href="https://www.instagram.com/sammusicservice/" rel="noopener noreferrer" target="_blank"><InstagramIcon fontSize="large"/></a>
          </footer></div>
    );
  }
}

export default Footer;
