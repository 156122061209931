import React, { Component } from 'react';
import parse from "html-react-parser";
import './LessonReportConfirmation.css';


class LessonReportConfirmation extends Component {
  render() {

    var absent_alert;
    if(this.props.absent) {
      absent_alert = (
        <div className="error">
        The student was absent for this lesson.
        <br />
        Reason: {this.props.absent_reason}
        </div>);
    }

    return (
      <div className="popup">
        <h3>Confirm Lesson Report</h3>
        <ul>
          <li><strong>Customer:</strong> {this.props.lesson.customers}</li>
          <li><strong>When:</strong> {this.props.lesson.when}</li>
        </ul>
        <h3>Covered in Lesson</h3>
        <div className="confirmFeedback">{parse(this.props.covered)}</div>
        <h3>Homework and Practice</h3>
        <p>{parse(this.props.homework)}</p>
        { absent_alert }
          {this.props.comment ? (
          <div>
            <h3>Comment - {this.props.comment.options[this.props.comment.selectedOption].name}</h3>
            <p>
              {this.props.comment.comment ? parse(this.props.comment.comment) : ""}
            </p>
          </div>
        ) : null}
        <button className="btn" onClick={this.props.confirm}>Submit Report</button>
        <button className="btn" onClick={this.props.untrigger}>Cancel</button>
      </div>
    );
  }
}

export default LessonReportConfirmation;
