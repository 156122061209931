import React, { Component } from "react";
import './QuillTextbox.css';

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

let Font = Quill.import("formats/font");
Font.whitelist = ["times-new-roman", "arial",  "roboto", "open-sans", "montserrat", "raleway", "roboto-mono"];
Quill.register(Font, true);

let Size = Quill.import("formats/size");
Size.whitelist = ["10px", "12px", "14px", "16px", "18px", "20px"];
Quill.register(Size, true);

class QuillTextbox extends Component {
  constructor() {
    super();
    this.state = {
      value: "",
    };
  }

  modules = {
    toolbar: [
      [{ "size": ["10px", "12px", "14px", "16px", "18px", "20px"] }],
      [{ font: ["lato", "arial", "times-new-roman", "roboto", "open-sans", "montserrat", "raleway", "roboto-mono"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
  };

  handleChange = string => {
    let e = {
      target: {
        name: this.props.name,
        type: 'text',
        value: string
      },
    };
    this.setState({value: string})
    this.props.handleChange(e)
  };

  render() {
    return (
      <ReactQuill
        modules={this.modules}
        value={this.state.value}
        onChange={this.handleChange}
      ></ReactQuill>
    );
  }
}

export default QuillTextbox;
