import React, { Component } from 'react';

import { UpcomingDash, PreviousDash, ReportDash, ShopDash, CustomerResourcesDash, TutorResourcesDash, LocationResourcesDash, ParentBlogDash, TutorBlogDash, GradeAndBooksDash, ReportsAndLessonsDash, PracticeFolderDash, StudentPromotionsDash, ParentGradeSyllabus } from './DashItems.js';
import LessonWidget from './LessonWidget.js';
import { RequireLogin } from './Login.js';
import TutorReady from './TutorReady'

class Dashboard extends Component {
	constructor() {
		super();

		this.state = {
			dashitems: []
		};

		this.mounted = false;
	}

	componentWillMount() {
		this.mounted = true;

	    this.setState({ dashitems: JSON.parse(localStorage.getItem('dashitems')) });
	}

  componentDidMount() {
    window.scrollTo(0, 0);
  }

	componentWillUnmount() {
		this.mounted = false;
	}

	render() {
		var display_items = [];

		if(this.state.dashitems) {

			if(this.state.dashitems.indexOf('upcoming') >= 0)
				display_items.push(<UpcomingDash key="upcoming" />);

			if(this.state.dashitems.indexOf('previous_dash') >= 0)
				display_items.push(<PreviousDash key="previous" />);

      if(this.state.dashitems.indexOf('lesson_reports') >= 0)
        display_items.push(<ReportsAndLessonsDash key="lesson_reports" />)

      if(this.state.dashitems.indexOf('practice_folders') >= 0)
        display_items.push(<PracticeFolderDash key="practice_folders" />)

      if(this.state.dashitems.indexOf('promotions') >= 0)
				display_items.push(<StudentPromotionsDash key="promotions" />);

      if(this.state.dashitems.indexOf('parent_grade_syllabus') >= 0)
        display_items.push(<ParentGradeSyllabus />)

			if(this.state.dashitems.indexOf('report') >= 0)
				display_items.push(<ReportDash key="report" />);

      if(this.state.dashitems.indexOf('parent_blog') >= 0)
        display_items.push(<ParentBlogDash key="parent_blog" />)

			if(this.state.dashitems.indexOf('shop') >= 0)
				display_items.push(<ShopDash key="shop" />);

      if(this.state.dashitems.indexOf('student_gradesbooks') >= 0)
        display_items.push(<GradeAndBooksDash key="student_gradesbooks" />)

      if(this.state.dashitems.indexOf('tutor_blog') >= 0)
        display_items.push(<TutorBlogDash key="tutor_blog" />)

      // if(this.state.dashitems.indexOf('payment') >= 0)
			// 	display_items.push(<PaymentDash key="payment" />);

			if(this.state.dashitems.indexOf('resources_cust') >= 0)
				display_items.push(<CustomerResourcesDash key="resources_cust" />);

			if(this.state.dashitems.indexOf('resources_tutor') >= 0)
				display_items.push(<TutorResourcesDash key="resources_tutor" />);

			if(this.state.dashitems.indexOf('resources_loc') >= 0)
				display_items.push(<LocationResourcesDash key="resources_loc" />);
		}

		return (
			<div className="dashboard clearfix">
				<RequireLogin />
        <TutorReady />
				<h2>Dashboard</h2>
				<ul className="dash__list float_left">
					{display_items}
				</ul>
				<LessonWidget />
			</div>
			);
	}
}

export default Dashboard;
