import React, { Component } from 'react';
// import './Header.css';
import { RequireLogin } from './Login.js';
import TutorReady from './TutorReady'
import LessonList from './LessonList.js';

class PreviousLessons extends Component {
	constructor(params) {
		super(params);

		this.page = 1;
		if(params.match.params && params.match.params.page)
			this.page = params.match.params.page;
	}

  title = window.location.pathname === "/reports" ? "Lesson Recordings + Reports" : "Previous Lessons"

  render() {
    return (
      <div className="previous">
    	<RequireLogin />
        <TutorReady />
	      <h2>{this.title}</h2>
      	<LessonList report="0" type="-1" page={this.page} history={this.props.history}/>
      </div>
    );
  }
}

export default PreviousLessons;
