import React, { Component } from 'react';
import './Tutorial.css';
class GDPR extends Component {

    render() {
        return (
            <div className="textpage">
                <div>
                    <div className="video-embed">
                        <iframe title="tutorial" src="https://www.youtube.com/embed/OhJAAdvWcBQ?rel=0" id="fitvid0"></iframe>
                    </div>
                    <h2>Example&nbsp;lesson report</h2>
                    <h3>Covered in lesson:</h3>
                    <p>The first section is where you write what happened in the lesson. A typical report would include what scales you warmed up with and what memory games you might have played or any sight reading they tackled. You may want to comment on what practice was covered from last week and if the homework set was achieved. Any points of interest can be included here, such as a particular fingering or techniques that were covered. Writing the theory down is important, if you have covered intervals / dynamics or time signatures for example, make sure it’s included as the student may not remember this otherwise. If they are struggling with anything and will need a reminder for practice, you can cover this in here and the homework section. You must also write in what books have been used and what pages were covered (essential if a cover tutor is required if you fall ill) Any behavioural issues can be written in the report but in a positive way, mention the productive things that were achieved while still drawing attention to the parent that there may be an issue that needs discussing. This is only required in the issue is of a serious nature.</p>
                    <h3>Homework and practice:</h3>
                    <p>The homework and practice section, similar to the first section must include details of what books, pages and pieces are to be worked on at home. Again, any particular techniques or relavent scales, arpeggios, chords, theory or grade work must be included. Feel free to engage the student within the lesson report, it can be useful to make notes throughout the lesson and summarise it at the end with the student, so they are aware of where there homework is set, it also saves you having to write it all up from memory later that evening. This is an opportunity to praise the student for their work and give them confidence to continue and enthuse them to want to practice. Please remember that the parents and schools will read this, so it can be friendly and aimed at the student or parent, but needs to be professional and therefor avoid any slang or anything that could be deemed inappropriate. If the student requires a book, please give as much detail as possible to the parent for them to purchase it, specifically the full name and author. In the case of not having internet at the school, you can write the reports up on notepad for example, then copy them over when you have internet later.</p>
                    <h2>Student absent&nbsp;example&nbsp;lesson report</h2>
                    <h3>Covered in lesson:</h3>
                    <p>In the event of a student not attending a lesson, a full lesson report is still required. This must start by acknowledging they were absent.</p>
                    <h3>Homework and practice:</h3>
                    <p>You can comment on what you asked them to practice and elaborate on that presuming they have achieved what was requested. You may want to set them additional homework, which could be progressing through the book they are working on, advancing on certain techniques or developing scales to 2 octaves for example. If you have been told they are unwell you could wish them to get well soon, perhaps set some listening homework and try to still get some practice in!</p>
				</div>
            </div>
        );
    }
}

export default GDPR;
