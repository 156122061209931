import React, { Component } from 'react';
import './Lesson.css';
import parse from "html-react-parser";
import QuillTextbox from './QuillTextbox'
import { jsPDF } from "jspdf";

import LessonReportConfirmation from './LessonReportConfirmation.js';
import LessonComment from './LessonComment';
import CountdownTimer from './CountdownTimer'

import API from '../classes/API.js';

class Lesson extends Component {
	constructor(props) {
		super(props);
    this.emptyQuillString = "<p><br></p>"

		this.state = {
			lesson: {
				customers: [],
				tutor: undefined
			},
			submittable: false,
			covered: "",
			homework: "",
			lessonreport: {},
      errors: [],
      comment_data: undefined,
			confirmation_trigger: false,
			other_lessons: [],
      zoom_link: undefined,
      zoom_download: undefined,
      zoom_stream: undefined,
      tutor_gradesandbooks: undefined,
      practice_folders: undefined
		}

		this.lessonkey = props.match.params.key;

		this.mounted = false;

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleConfirmationCancel = this.handleConfirmationCancel.bind(this);
		this.handleConfirm = this.handleConfirm.bind(this);
		this.getLessonDetails = this.getLessonDetails.bind(this);

		this.doCountdown = this.doCountdown.bind(this);

		this.viewLesson = this.viewLesson.bind(this);
	}

	componentWillMount() {
		this.mounted = true;
		this.getLessonDetails();
	}

	doCountdown() {
		var d = new Date();
		if(this.state.lesson) {
			let regexp = /([a-zA-Z]+ [0-9]+ [a-zA-Z]+ [0-9]+ )\n([0-9:]+)/g;
			let str = this.state.lesson.when;
      if(!str) return

			let array = [...str.matchAll(regexp)];
			var t = new Date(Date.parse(array[0][1] + array[0][2]));
      let tutorCustomerOffset = (this.state.lesson.ages || this.state.lesson.genders || this.state.lesson.bios || this.state.lesson.specialneeds) ? 900 : 0

			if(t) {
				var current = d.getTime();
				var target = t.getTime();

				var diff = target - current;
        if(diff < -(this.state.lesson.length * 900000)) {
						this.setState({ zoom_link: (<li>This lesson has ended</li>) });
          } else if ((diff < (tutorCustomerOffset * 1000) && diff > 0) || (diff < 0 && diff > -(this.state.lesson.length * 900000))) {
						this.setState({ zoom_link: (<li><a target="_blank" rel="noopener noreferrer" href={this.state.lesson.zoomurl}>Join online session</a></li>) });
          } else if (diff > 86400000) {
						this.setState({ zoom_link: (<li>Link available on day of session</li>) });
          }
          else {
          this.setState({ zoom_link: (<li><b>Link available in <CountdownTimer refreshPeriodSecs={60} targetTimeSecs={(target/1000) - (tutorCustomerOffset)}/></b></li>) });
          }
			}
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

  componentDidUpdate(prevProps, prevState) {
    if(prevState.lesson !== this.state.lesson) {
      if(this.state.lesson.zoomurl) {
         this.getDownloadLink();
      }
      if(this.state.lesson.tutor_id) {
        this.get_gradesandbooks()
      }
      if(!this.state.practice_folders) {
        this.get_resources()
      }
      if(this.state.lesson.when && (this.state.lesson.location.includes("SAM ONLINE") || this.state.lesson.location.includes("Online SAM Lessons") )) {
       this.doCountdown()
       this.intervalID = setInterval(this.doCountdown, 30000);
      }
    }
  }

  commentHandler = (commentData) => {
    this.setState({'comment_data': commentData})
  };


	getLessonDetails() {

		var l = JSON.parse(localStorage.getItem('lesson_' + this.lessonkey));

		if (l)
			this.setState({ lesson: l, lessonreport: l.lessonreport, covered: l.lessonreport.report, homework: l.lessonreport.homework });

		var api = new API();

		var that = this;
		api.get('api/lesson/' + this.lessonkey, d => {
			if (d.status && d.status === 'ok') {
				if (that.mounted) {
					that.setState({ 'lesson': d.lesson, 'lessonreport': d.lesson.lessonreport, 'submittable': d.submittable, 'covered': d.lesson.lessonreport.report, 'homework': d.lesson.lessonreport.homework, 'other_lessons': d.other_lessons });
					this.startCountdown();
				}


				localStorage.setItem('lesson_' + this.lessonkey, JSON.stringify(d.lesson));
			} else {
				window.location.href = "/404";
			}
		});
	}

	componentWillUnmount() {
		this.mounted = false;
		clearInterval(this.intervalID);
	}

  handleChange(e) {
		const name = e.target.name;
		var value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
    if(value === this.emptyQuillString)  value = "";

		this.setState(prevState => {
			const newState = {...prevState};
			newState[name] = value;
			return newState;
		});
	}

	handleSubmit(e) {
		e.preventDefault();
    if(this.state.comment_data && this.state.comment_data.comment && this.state.comment_data.comment === '<p><br></p>') this.setState({commentData: {...this.state.commentData, comment: undefined}})

		this.setState({
			'homework': this.state.homework.trim(),
			'covered': this.state.covered.trim(),
			'errors': []
		});

    var newErrors = []

    let reportErrorString = "Please complete both sections of the report."
		if(!this.state.covered || !this.state.homework) {
			if(!newErrors.includes(reportErrorString)) newErrors.push(reportErrorString);
    }

  if(this.state.comment_data && this.state.comment_data.leaveComment && (!this.state.comment_data.selectedOption || !this.state.comment_data.comment)) {
      let errorString;
      if(!this.state.comment_data.selectedOption) errorString = "Please specify the reason for the comment."
      else if(!this.state.comment_data.comment) errorString =   'Please give context for the comment, or add "N/A" if a once-off absence.'
			if(!newErrors.includes(errorString)) newErrors.push(errorString);
		}

		this.setState({ 'errors': newErrors });
    if(newErrors.length > 0) return

		this.setState({ confirmation_trigger: true });
	}

	handleConfirm(e) {
		e.preventDefault();

		this.setState({ confirmation_trigger: false });

		var api = new API();
		var that = this;
		api.post('api/lesson/'+this.lessonkey+'/report',
			{
				'covered': this.state.covered,
				'homework': this.state.homework,
				'absent': this.state.absent,
				'comment': this.state.comment_data
			},
			d => {
			if(d.status && d.status === 'ok') {
				if(that.mounted) {
					that.setState(
						{
							'covered': d.lessonreport.covered,
							'homework': d.lessonreport.homework,
							'lessonreport': d.lessonreport,
							'submittable': false
						});
				}
			} else {
				let newErrors = this.state.errors;
				newErrors.push("There was an error submitting this report.");
				this.setState({ 'errors': newErrors });
			}
		});
	}

	handleConfirmationCancel(e) {
		e.preventDefault();

		this.setState({ confirmation_trigger: false });
	}

	viewLesson(e) {
		var lessonkey = e.currentTarget.attributes["data-key"].value;
		this.lessonkey = lessonkey;
		this.getLessonDetails();

		return false;
	}

  calculateStartOfLesson(bookingTime, bookingIndex) {
    let lessonInterval = 900 // 15 mins
    return parseInt(bookingTime - (lessonInterval * (bookingIndex-1)))
  }

  getDownloadLink() {
    let startOfLesson = this.calculateStartOfLesson(this.state.lesson.mktime, this.state.lesson.booking_index)
    if(JSON.parse(localStorage.getItem("user"))['tutor_count'] > 0) return
    if(Math.round(new Date().getTime()/1000) > startOfLesson + 604800) {
      this.setState({zoom_download: `<span>Link Expired</span>`, zoom_stream: `<span>Link Expired</span>`})
      return
    }
    if(Math.floor(Date.now() / 1000) < startOfLesson) {
      this.setState({zoom_download: `<span>Lesson Not Started</span>`, zoom_stream: `<span>Lesson Not Started</span>` })
      setTimeout(() => this.getDownloadLink(), 300000)
      return
    }

    if(this.state.lesson.zoomurl) {
    let zoomUrlNoParams = this.state.lesson.zoomurl.split("?")
    let zoomUrlSplit = zoomUrlNoParams[0].split("/")
    let meetingId = zoomUrlSplit[zoomUrlSplit.length-1]

    var api = new API();
    api.get(`api/lesson/${meetingId}/${this.state.lesson.mktime}/download`, (response) => {
      if(response && response.status === "ok") {
        if(response.body.status === "available") {
          this.setState({zoom_download: `<a target="_blank" rel="noopener noreferrer" href='${response.body.links.download}'>Direct Download</a>`, zoom_stream: `<a target="_blank" rel="noopener noreferrer" href='${response.body.links.stream}'>Stream Now</a>`})
        } else {
          if(Math.round(new Date().getTime()/1000) > (startOfLesson) && Math.round(new Date().getTime()/1000) < (startOfLesson + (this.state.lesson.length * 900))) {
            let message = `<span>Lesson Started</span>`
            this.setState({zoom_download: message, zoom_stream: message})
            setTimeout(() => this.getDownloadLink(), 300000)
          } else if(Math.round(new Date().getTime()/1000) < (startOfLesson + (this.state.lesson.length * 900) + 86400)) {
            let message = `<span>Lesson Ended</span><div style="font-size: 12px;">Processing Recording</div>`
            this.setState({zoom_download: message, zoom_stream: message})
          } else {
            let message = `<span style="color: red">Recording not available</span>`
            this.setState({zoom_download: message, zoom_stream: message})
          }
        }
      } else {
        let message = `<span style="color: red">ERROR. Try again shortly.</span>`
        this.setState({zoom_download: message, zoom_stream: message})
      }
    })
    }
  }

  download_pdf() {
    var doc = new jsPDF('portrait', 'pt', 'a4');
    let customers = ''
    let date = new Date()
    for(let i=0; i<this.state.lesson.customers.length;i++) customers += `${this.state.lesson.customers[i].replace(" ", "")}-`
    let filename = `mySamReport-${customers}${date.getDate()}${date.getMonth()}${date.getFullYear()}`

    let pdf_area = document.getElementById('pdf_container')
    doc.html(pdf_area, {
      html2canvas: {
        scale: 0.48
      },
      x: 10,
      y: 10,
      callback: function (doc) {
        doc.save(filename);
      },
    });
  }

  get_gradesandbooks() {
    let user = JSON.parse(localStorage.getItem("user"))
    if(user.tutor_count === 0) return
    var api = new API()
		api.get('api/tutor/' + user.email, response => {
      if(response && response.tutor.gradesandbooks) {
        this.setState({ tutor_gradesandbooks: response.tutor.gradesandbooks })
      }
    })
  }

  get_resources() {
    let api = new API()
    api.get('api/find/' + this.state.lesson.cust_id, response => {
     if(response.practicefolders) {
       let matchers = [], result = {}
       this.state.lesson.customers.forEach(customer => {
         let customer_name = customer.replace("$&^", "")
         matchers.push(customer_name)
       })
       Object.keys(response.practicefolders).forEach(customer => {
         let customer_name = customer.replace("$&^", " ")
         if(matchers.indexOf(customer_name) > -1) {
          Object.keys(response.practicefolders[customer]).forEach(instrument => {
            if(this.state.lesson.instrument === instrument) {
              result = {...result, [customer]: {[instrument]: response.practicefolders[customer][instrument]} }
            }
          })
         }
       })
      this.setState({'practice_folders': result})
     }
    })
  }

	render() {

		var studentcontent = [];
		if(this.state.lesson.ages || this.state.lesson.genders || this.state.lesson.bios || this.state.lesson.specialneeds) {
			for (var i = this.state.lesson.customers.length - 1; i >= 0; i--) {
				var c = this.state.lesson.customers[i];

				if(this.state.lesson.ages) {
					var age = this.state.lesson.ages[i];
					if(age < 0) {
						age = 'N/A';
					}
				}

				if(this.state.lesson.genders) {
					var gender = this.state.lesson.genders[i];
					if(gender === '') {
						gender = 'N/A';
					}
				}

				if(this.state.lesson.bios){
					var bio = this.state.lesson.bios[i];
					if(!bio || bio === '') {
						bio = 'N/A';
					}
				}

				if(this.state.lesson.specialneeds){
					var sen = this.state.lesson.specialneeds[i];
					if(!sen || sen === '') {
						sen = 'N/A';
					}
				}

				studentcontent.push(<div id="student_notes" key={i}>
					<h3>{c}</h3>
					<b>Age:</b> {age}<br />
					<b>Gender:</b> {gender}<br />
					<div>
						<h3>Student Notes</h3>
              <div>{bio === this.emptyQuillString ? "N/A" : parse(bio)}</div>
					</div>
					<div>
						<h3>SEN</h3>
              <div>{sen === this.emptyQuillString ? "N/A" : parse(sen)}</div>
					</div>
				</div>);
			}

		}

		var covered;
		if(this.state.lesson && this.state.lessonreport && this.state.lessonreport.report) {
			covered = (<div>
					<h3>Covered in Lesson</h3>
            <div className="feedback">{this.state.lessonreport.report === this.emptyQuillString ? "N/A" : parse(this.state.lessonreport.report)}</div>
				</div>);
    } else if (this.state.lesson && this.state.submittable && JSON.parse(localStorage.getItem("user"))['tutor_count'] > 0) {
			covered = (
				<div>
					<h3>Covered in Lesson</h3>
					<p>(Book and page number / scales and warmups / techniques and exercises)</p>
            <QuillTextbox handleChange={this.handleChange} name="covered" />
				</div>
			);
    } else {
      covered = (<div>
          <div className="feedback">Report currently unavailable.</div>
            </div>)
    }

		var homework = "";
		if(this.state.lesson && this.state.lessonreport && this.state.lessonreport.homework){
			homework = (<div>
					<h3>Homework and Practice</h3>
            <div className="feedback">{this.state.lessonreport.homework === this.emptyQuillString ? "N/A" : parse(this.state.lessonreport.homework)}</div>
				</div>);
    } else if (this.state.lesson && this.state.submittable && JSON.parse(localStorage.getItem("user"))['tutor_count'] > 0) {
			homework = (
				<div>
					<h3>Homework and Practice</h3>
					<p>
						(Book and page number / scales and warmups / techniques and exercises / important information to parents regarding book purchases or grade exams)<br />
						For instrument / accessory purchases please recommend https://shop.ucanplay.org.uk/sam<br />
						If a student is absent please still write a detailed lesson report:<br />
					</p>
            <QuillTextbox handleChange={this.handleChange} name="homework" />
				</div>
			);
    }


    if (JSON.parse(localStorage.getItem("user"))['tutor_count'] > 0) {
          var comment;
      if(this.state.lesson && this.state.lessonreport && this.state.lessonreport.comment) {
        comment = (
          <div>
            <h3>Comment - {this.state.lessonreport.comment_type}</h3>
              <div className="feedback">{parse(this.state.lessonreport.comment)}</div>
          </div>)
      }

      var comment_checkbox = <LessonComment onChange={this.commentHandler}/>

		var absent_reason;
		if(this.state.absent) {
			absent_reason = (
				<div>
					<br />
					<select className="lessonreport__select" name="absent_reason" onChange={this.handleChange}>
						<option selected disabled>Select a reason</option>
						<option>Illness</option>
						<option>Holiday</option>
						<option>School trip</option>
						<option>Clash with other academic activities (please contact SAM office)</option>
						<option>Unknown (please contact SAM office)</option>
						<option>Other (please contact SAM office)</option>
					</select>
					<br />
				</div>
				);
		}

		var button = (
				<div>
					<br />
					<input type="submit" className="lessonreport__submit btn" value="Submit Report" />
					<br />
				</div>
				);
    }

		var report_content;
		if(this.state.lesson && this.state.submittable) {
			report_content = (
				<form onSubmit={this.handleSubmit}>
					{covered}
					{homework}
          {comment_checkbox}
					{absent_reason}
				<div className="error">
					{parse(this.state.errors.join('<br />'))}
				</div>
					{button}
				</form>
				)
		} else {
			report_content = (
				<div>
					{covered}
					{homework}
          {comment}
				</div>
				)
		}

		var absent_alert;
		if(this.state.lessonreport && this.state.lessonreport.present === 0) {
			absent_alert = (
				<div className="error">
				The student was absent for this lesson.
				</div>
				);
    }

    var comment_alert;
    if(this.state.lessonreport && this.state.lessonreport.comment) {
      comment_alert = (
        <div className="error">
          {this.state.lessonreport.visible_comment}
          </div>
      )
    }

		var confirmation;
		if(this.state.confirmation_trigger) {
        confirmation = <LessonReportConfirmation confirm={this.handleConfirm} untrigger={this.handleConfirmationCancel} absent={this.state.absent} absent_reason={this.state.absent_reason} covered={this.state.covered} homework={this.state.homework} lesson={this.state.lesson} comment={this.state.comment_data}/>


		}

		var loc_display;
		if(this.state.lesson.map_link) {
			loc_display = (<a href={this.state.lesson.map_link} target="_blank" rel="noopener noreferrer">{this.state.lesson.location}</a>);
		} else {
			loc_display = this.state.lesson.location;
		}

		var other_lessons_display;
		if(!this.state.submittable && this.state.other_lessons && this.state.other_lessons.length > 0) {
			var other_lessons = this.state.other_lessons.map(function(curr, index, arr) {
				return (
					<tr key={curr.key} data-key={curr.key} onClick={this.viewLesson}>
						<td>{curr.customers.join(' + ')}</td>
						<td>{curr.tutor}</td>
						<td>{curr.location}<br />{curr.room}</td>
						<td>{curr.instrument}</td>
						<td>{curr.when}</td>
					</tr>
				);
			}, this);

			other_lessons_display = (
				<div>
					<h2>Other lessons:</h2>
					<table className="lessonlist">
						<thead className="lessonlist--head">
							<tr>
								<td>Student</td>
								<td>Tutor</td>
								<td>Location</td>
								<td>Instrument</td>
								<td>When</td>
							</tr>
						</thead>
						<tbody>
							{other_lessons}
						</tbody>
					</table>
				</div>
				);
		}

		var zoom_display;
		if(this.state.zoom_link) {
      zoom_display = JSON.parse(localStorage.getItem("user"))["location_count"] > 0 ? "" : this.state.zoom_link;
		}

    var zoom_download_display;
    if(this.state.zoom_download) {
      zoom_download_display = (<div><li><b>Recorded Video:</b> {parse(this.state.zoom_download)}</li>
        <li><b>Streamed Video:</b> {parse(this.state.zoom_stream)}</li></div>)
    }

    var pdf_display;
    if(!this.state.submittable && this.state.lesson && this.state.lesson.lessonreport && this.state.lesson.lessonreport.report) {
      pdf_display = <li><b>PDF Report:</b> <button className="onclick_url" onClick={() => this.download_pdf()}>Download</button></li>
    }

    var tutor_gradesandbooks_display;
    if(this.state.tutor_gradesandbooks) {
      var url = this.state.tutor_gradesandbooks.substring(0,4) === 'http' ? this.state.tutor_gradesandbooks : `https://${this.state.tutor_gradesandbooks}`
      tutor_gradesandbooks_display = parse(`<li><b>Grades And Books:</b> <a target="_blank" rel="noopener noreferrer" href="${url}">View</a></li>`)
    }

    var resources_display = "";
    if(this.state.lesson) {
      let matchers = []
      this.state.lesson.customers.forEach(customer => matchers.push(customer))
      for(let customer in this.state.practice_folders) {
        for(let key in this.state.practice_folders[customer]) {
          resources_display += `<li><b>Practice Folder:</b> <a target="_blank" rel="noopener noreferrer" href=${this.state.practice_folders[customer][key]}>${customer.split("$&^")[0]} ${key}</a></li>`
        }
      }
    }

    const populate_shadow_tutor = () => {
      if (this.state.lesson.shadow_tutors && this.state.lesson.shadow_tutors.length > 0) {
        return <li><b>Shadow Tutors:</b> {this.state.lesson.shadow_tutors.join(", ")} </li>
      }
    }

		return (
      <div className="resources">
        <div id="pdf_container">
				<h2>Lesson Details</h2>
				{absent_alert}
        {comment_alert}
        <div className="lessonDetails">
				  <ul className="lessonInfo">
            <li><b>Tutor:</b> {this.state.lesson.tutor}</li>
            {populate_shadow_tutor()}
            <li><b>Customers:</b> {this.state.lesson.customers.join(' + ')}</li>
            <li><b>Location:</b> {loc_display}</li>
            <li><b>Room:</b> {this.state.lesson.room}</li>
            <li><b>Instrument:</b> {this.state.lesson.instrument}</li>
            <li><b>When:</b> {this.state.lesson.when}</li>
					  {zoom_display}
          </ul>
          <ul className="lessonResources">
            {tutor_gradesandbooks_display}
            {parse(resources_display)}
            {pdf_display}
            {zoom_download_display}
          </ul>
        </div>
				{studentcontent}
				<h2>Lesson Report</h2>
				{report_content}
          {confirmation}
          </div>
				{other_lessons_display}
			</div>
			);
		}
	}

	export default Lesson;
