import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import Header from './components/Header.js';
import Footer from './components/Footer.js';

import Dashboard from './components/Dashboard.js';
import UpcomingLessons from './components/UpcomingLessons.js';
import PreviousLessons from './components/PreviousLessons.js';

import Payment from './components/Payment.js';
import GDPR from './components/GDPR.jsx';
import Grades from './components/Grades.jsx';
import Tutorial from './components/Tutorial.jsx';
import InvoiceList from './components/InvoiceList.jsx';

import LessonView from './components/Lesson.js';
import Report from './components/Report.js';

import { Login, Logout } from './components/Login.js';
import ChangePassword from './components/ChangePassword.jsx';

import PageNotFound from './components/PageNotFound.jsx';

import Banner from './components/Banner.js';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      logged_in: localStorage.getItem('token') ? true : false,
      dashitems: []
    };

    var items = JSON.parse(localStorage.getItem('dashitems'));
    if(items)
      this.state.dashitems = items;

    console.log('constructing');

    this.handleDashitemsChange = this.handleDashitemsChange.bind(this);
  }

  handleDashitemsChange(dashitems) {
    if(dashitems && dashitems !== "undefined")
      this.setState({ 'dashitems': dashitems });

    console.log("Setting dash items", dashitems);

    localStorage.setItem('dashitems', JSON.stringify(dashitems));
  }


  componentDidMount() {
    if (this.state.logged_in) {
      if(localStorage.getItem('token') === null || localStorage.getItem('token') === 'undefined') {
        window.href = '/logout';
        return;
      }
    }
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Header items={this.state.dashitems} />
          <div className="container">
            <div className="container__inner">
              {window.location.pathname === '/login' ? "" : <Banner/> }
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/upcoming" component={UpcomingLessons} />
                <Route exact path="/previous" component={PreviousLessons} />
                <Route path="/reports" component={PreviousLessons} />
                <Route path="/upcoming/:page" component={UpcomingLessons} />
                <Route path="/previous/:page" component={PreviousLessons} />

                <Route exact path="/invoicing" component={InvoiceList} />

                <Route exact path="/report" component={Report} />
                <Route exact path="/report/:page" component={Report} />

                <Route exact path="/unreported" component={Report} />
                <Route exact path="/unreported/:page" component={Report} />

                <Route path="/lesson/:key" component={LessonView} />

                <Route exact path="/payment" component={Payment} />

                <Route exact path="/gdpr" component={GDPR} />
                <Route exact path="/grades" component={Grades} />
                <Route exact path="/tutorial" component={Tutorial} />

                <Route exact path="/changepassword" component={ChangePassword} />

                <Route exact path="/login" component={ (props) => <Login handleDashitemsChange={this.handleDashitemsChange} {...props} /> } />
                <Route exact path="/logout" component={ (props) => <Logout handleDashitemsChange={this.handleDashitemsChange} {...props} /> } />

                <Route component={PageNotFound} />
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
