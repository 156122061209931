import React, { Component } from 'react';
import './LessonList.css';

import API from '../classes/API.js';

import { Link } from 'react-router-dom';

class LessonList extends Component {
	constructor(props) {
		super(props);

    var showall = false;
    var page = 1;
    if(props.page && parseInt(props.page))
      page = props.page;
    else if(props.page && props.page === 'all')
      showall = true;

    let relation = 'upcoming';
    if(this.props.type === 0 || this.props.type === "0")
      relation = 'unreported';
    else if(this.props.type < 0)
      relation = 'previous';

    this.per_page = 10;

		this.state = {
			lessons: [],
      relation: relation,
      page: page,
      showall: showall,
      past: "/" + relation + "/" + (page > 1 ? (page-1) : page),
      next: "/" + relation + "/" + (page + 1),
      all: "/" + relation + "/all",
      loading: false
		}

    this.showReport = true;
    this.mounted = false;

		this.getLessons = this.getLessons.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.showall = this.showall.bind(this);
    this.viewLesson = this.viewLesson.bind(this);
	}

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
      window.scrollTo(0, 0);
  }

	getLessons(type = 1, page = 1, showall=false) {
    let relation = 'upcoming';
    if(type === 0 || type === "0")
      relation = 'unreported';
    else if (type < 0)
      relation = 'previous';


    var l = JSON.parse(localStorage.getItem(relation+'Lessons'));
    if(l)
      this.setState({ lessons: l });

    var that = this;
    var api = new API();
    this.setState({ 'loading': true });
    api.get('api/lessons/'+relation, d => {
      if(that.mounted)
        that.setState({ loading: false });

      if(d.status && d.status === 'ok') {
        if(that.mounted)
          that.setState({ lessons: d.lessons });

        localStorage.setItem(relation+'Lessons', JSON.stringify(d.lessons));
      }
    });
	}

  prevPage(e) {
    e.preventDefault();
    var p = parseInt(this.state.page) - 1;
    if(this.state.showall)
      p = 1;
    this.setState({ showall: false });
    var max_p = Math.ceil(this.state.lessons.length / this.per_page);
    if(p > 0 && p <= max_p) {
      this.setState({ page: p });
      this.props.history.push('/' + this.state.relation + '/' + p);
    }

    return false;
  }

  nextPage(e) {
    e.preventDefault();
    var p = parseInt(this.state.page) + 1;
    if(this.state.showall)
      p = 1;
    this.setState({ showall: false });
    var max_p = Math.ceil(this.state.lessons.length / this.per_page);
    if(p > 0 && p <= max_p) {
      this.setState({ page: p });
      this.props.history.push('/' + this.state.relation + '/' + p);
    }

    return false;
  }

  showall(e) {
    e.preventDefault();
    this.setState({ showall: true, page: 1 });
    this.props.history.push('/' + this.state.relation + '/all');
    return false;
  }

  viewLesson(e) {
    var lessonkey = e.currentTarget.attributes["data-key"].value;

    this.props.history.push('/lesson/' + lessonkey);

    return false;
  }

  render() {
  	var rows = [];
    var header;
    var headerLength = 0;

    if(this.showReport) {
      header = <tr>
        <td>Student</td>
        <td>Tutor</td>
        <td>Location</td>
        <td>Instrument</td>
        <td>When</td>
        <td>Report</td>
      </tr>;
      headerLength = 6;
    } else {
      header = <tr>
            <td>Student</td>
            <td>Tutor</td>
            <td>Location</td>
            <td>Instrument</td>
            <td>When</td>
          </tr>;
      headerLength = 5;
    }

    var start = (this.state.showall) ? 0 : (this.state.page - 1) * this.per_page;
    var toshow = [];
    if(this.state.showall) {
      toshow = this.state.lessons;
    } else {
      toshow = this.state.lessons.slice(start, start+this.per_page);
    }

    if(toshow && toshow.length > 0) {
    	for(var i = 0; i < toshow.length; i++) {
        if(this.showReport) {
          var report_link = (toshow[i].lessonreport && toshow[i].lessonreport.homework !== "" ? "View" : "Unavailable")
      		rows.push(<tr key={toshow[i].key} data-key={toshow[i].key} onClick={this.viewLesson}>
      			<td>{toshow[i].customers.join(' + ')} <span className="lessonlist__mobileonly"><br />({toshow[i].instrument})</span></td>
      			<td>{toshow[i].tutor}</td>
      			<td>{toshow[i].location}<br />{toshow[i].room}</td>
      			<td>{toshow[i].instrument}</td>
      			<td>{toshow[i].when}</td>
      			<td>{report_link}</td>
      			</tr>);
        } else {
          rows.push(<tr key={toshow[i].key} data-key={toshow[i].key} onClick={this.viewLesson}>
            <td>{toshow[i].customers.join(' + ')} <span className="lessonlist__mobileonly"><br />({toshow[i].instrument})</span></td>
            <td>{toshow[i].tutor} <br/> {toshow[i].shadow_tutors.join("</br>")}</td>
            <td>{toshow[i].location}<br />{toshow[i].room}</td>
            <td>{toshow[i].instrument}</td>
            <td>{toshow[i].when}</td>
            </tr>);
        }
      }
  	} else {
      rows.push(<tr className="lessonlist--empty" key={0}><td colSpan={headerLength}>No lessons found</td></tr>);
    }

    var prev_link = "/" + this.state.relation + "/" + (parseInt(this.state.page)-1);
    var next_link = "/" + this.state.relation + "/" + (parseInt(this.state.page)+1);

    var spinner;
    if(this.state.loading) {
      var api = new API();
      spinner = api.getSpinner();
    }

    var lesson_links_test = JSON.parse(localStorage.getItem("user")).tutor_count > 0 ? "" : (<div><span style={{marginBottom: '10px', display: 'inline-block'}}><b>Please note, recordings are usually available within 2 hours but can take longer to process</b></span></div>)

    return (<div>
      <span style={{marginBottom: '10px', display: 'inline-block'}}>Please click on the lesson for more information</span><br />
        { lesson_links_test }
      <br />
      {spinner}
      <div className="lessonlist__wrapper">
      	<table className="lessonlist">
      		<thead className="lessonlist--head">
            {header}
      		</thead>
      		<tbody>
      			{rows}
      		</tbody>
      	</table>
      </div>
      <div className="lessonlist__nav">
        <div className="lessonlist__nav__item">
          <a href={prev_link} onClick={this.prevPage}>&larr;</a>
        </div>
        <div className="lessonlist__nav__item">
          <Link to={this.state.all} onClick={this.showall}>Show All</Link>
        </div>
        <div className="lessonlist__nav__item">
          <a href={next_link} onClick={this.nextPage}>&rarr;</a>
        </div>
      </div>
    </div>);
  }

  componentWillMount () {
    this.mounted = true;
    if(this.props.report) {
      this.showReport = (this.props.report === "1" ? true : false);
    }

  	this.getLessons(this.props.type, this.state.page, this.state.showall);
  }
}

export default LessonList;
