import React, { Component } from 'react';
// import './Header.css';

class PageNotFound extends Component {
  render() {
    return (
      <div className="resources">
      	<h2>Page Not Found</h2>
      	<p>The page you were looking for was not found. If you came here via a link on the website please alert SAM Music Service about the issue.</p>
      </div>
    );
  }
}

export default PageNotFound;
