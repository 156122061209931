import React, { Component } from 'react';
// import './GDPR.css';
class GDPR extends Component {

	render() {
		return (
			<div className="textpage">
				<h2>SAM GDPR</h2>
				<p>GDPR is a new European directive that protects the residents of Europe and we forsee it being used world wide very soon.</p>
				<h3>Are SAM GDPR Compliant?</h3>
				<p>Yes, we are. There were minor changes we put in place, but have been aware of data protection for a long time. </p>
				<h3>What have we done to become GDPR compliant?</h3>
				<p>The main areas (there are more but these are the important ones) include:</p>
				<ol>
				<li>
				<p>Consulting with data handling professionals.</p>
				</li>
				<li>
				<p>Gaining clarity for the customer of what data we collect.</p>
				</li>
				<li>
				<p>Ensure we have Data Processing Agreements with the companies we use to store our data.</p>
				</li>
				<li>
				<p>Making sure we can delete your information if we need to.</p>
				</li>
				<li>
				<p>Giving you the option to opt out of mail or SMS promotional content.</p>
				</li>
				</ol>
				<h3>1. What personal data we hold about you:</h3>
				<p>Everything we hold about you is in our Data Processing Agreement&nbsp;which can be accessed in PDF form within your mySAM resources.</p>
				<h3>2. Data Processing Agreements</h3>
				<p>We have DPAs with our sub-processors which hold your personal data. Those sub-processors are mentioned in our <a href="https://betterproposals.io/dpa" target="_blank" rel="noopener noreferrer">DPA</a> which is available within the resources section of your mySAM.</p>
				<p>These sub-processors have either changed their Terms of Service to accommodate standard GDPR data processing information or we have a separate Data Processing Agreement with them.</p>
				<h4>SAM sub-processors</h4>
				<p>Sub-processors engaged by SAM</p>
				<p>For GDPR purposes, an overview of the sub-processors engaged by SAM:</p>
				<ul>
					<li><strong>SmartDebit</strong> – Secure storage of direct debit information</li>
					<li><strong>smsbroadcast</strong> – SMS text messaging service</li>
					<li><strong>MailChimp</strong> – Email messaging service</li>
					<li><strong>AWS</strong> – Amazon web hosting servers</li>
					<li><strong>QuickBooks</strong> – Secure banking software</li>
					<li><strong>Worldpay</strong> – Secure online payment software</li>
					<li><strong>Zoom</strong> – Video application</li>
				</ul>
				<p>We use our own internal software system for CRM and bookings.</p>
				<h3>3. Turning off emails and SMS contact</h3>
				<p>Within your mySAM, you can now change the options of email or SMS content. Simply navigate towards the top of the screen once logged in and adjust your settings accordinly. We email and SMS very rarely so we advise to keep these on as it’s always important content.</p>
				<h3>4. Housekeeping</h3>
				<p>There are hundreds of tiny little things, most of which are simply internal processes. The main thing is your rights.</p>
				<p>Your right to have your data removed being the main one.</p>
				<p>If this is something you wish to happen, you can simply email us.</p>
				<p>The other right which is popular is your right to see any data held about you. This is something we will be happy to provide on request.</p>
				<h3>Conclusion</h3>
				<p>SAM supports the GDPR processes put in place to make companies aware and responcible with their data. </p>
				<p>It is a new system in place and we will be continuing to develop alongside it to be compliant in all areas.</p>
			</div>
			);
		}
	}

export default GDPR;
