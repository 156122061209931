import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

import API from '../classes/API.js';

import './Login.css';

class Login extends Component {

	constructor(props) {
		super(props);

		if(localStorage.getItem('token'))
			window.location = '/';

		this.handleLogin = this.handleLogin.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handleForgotPassword = this.handleForgotPassword.bind(this);

		if(!this.state)
			this.state = {'email': '', 'password': ''};

		this.state.mounted = false;
	}

  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillMount() {
    this.mounted = true;
  }

  handleForgotPassword(e) {
  	e.preventDefault();

  	if(!this.state.email) {
      console.log(this.state.email)
  		this.setState({ 'errors': 'Please input your email.' });
      return
  	}

  	var api = new API();
  	api.post('api/forgot_password', {
  		username: this.state.email
  	}, d => {
  		if(d.status && d.status === 'ok') {
  			this.setState({ 'errors': '' });
  			alert("Please check your emails for further instructions.");
  		} else if(d.status && d.status === 'err' && d.error){
  			this.setState({ 'errors': d.error });
  		} else {
  			this.setState({ 'errors': 'Unknown error occurred. Please contact SAM.' });
  		}
  	});
  }

	handleLogin(e) {
		e.preventDefault();

		var api = new API();
		api.post('auth', {
			username: this.state.email,
			password: this.state.password
		}, d => {
			if(d.non_field_errors) {
				this.setState({ 'errors': 'Invalid credentials' });
			} else if(d.token && d.user && d.refresh_token && d.dashitems) {
				localStorage.setItem('token', d.token);
				localStorage.setItem('user', JSON.stringify(d.user));
				localStorage.setItem('refresh_token', d.refresh_token);

				this.props.handleDashitemsChange(d.dashitems);

				this.props.history.push('/');
			} else {

				var errString = "";
				for (var prop in d) {
					errString += prop.charAt(0).toUpperCase() + prop.slice(1) + ": " + d[prop];
				}

				this.setState({ 'errors': errString })
			}
		});
	}

	handleChange(e) {
		const name = e.target.name;
		const value = e.target.value;

		this.setState(prevState => {
			const newState = {...prevState};
			newState[name] = value;
			return newState;
		});
	}

	render() {
		return (
			<div className="login">
				<p>
				This is the login for SAM students, tutors and schools to view their mySAM details.<br />
				Please log in with your mySAM account email and password.<br />
				SAM cannot access your login details so please reset your password if you are unsure of your details or are having trouble logging in.<br />
				Please check your junk and spam folders if you cannot see the email.


				</p>
          <div className="login_outer_container">
            <div className="login_border">
              <h3>Login to mySAM.</h3>
            <div className="login_container">
				<form onSubmit={this.handleLogin}>
					<div className="error">
						{this.state.errors}
					</div>
					<br />
          <TextField margin="dense" label="Email" variant="filled" name="email" value={this.state.email} onChange={this.handleChange} />
					<br />
          <TextField margin="dense" label="Password" variant="filled" name="password" type="password" value={this.state.password} onChange={this.handleChange} />
					<br />
					<input className="btn login-btn" type="submit" value="Log in"/><br />
            <div className="forgotten_password">Forgotten your password?  <button className="onclick_url" onClick={this.handleForgotPassword} >Click Here!</button></div>
				</form>
          </div></div>
            </div>
              </div>
			);
	}
}

class Logout extends Component {
	constructor(props) {
		super(props);

		var api = localStorage.getItem('apiLocation');
		this.props.handleDashitemsChange([]);
		localStorage.clear();
		localStorage.setItem('apiLocation', api);
		this.props.history.push('/');
	}

	render() {
		return (
			<h2>Logged Out</h2>
			);
	}
}

class RequireLogin extends Component {
	constructor(props) {
		super(props);

		this.state = {logged_in: false};
	}

	componentWillMount() {
		if(localStorage.getItem('token'))
			this.setState({ logged_in: true });
	}

	render() {
		const { logged_in } = this.state;
		var redirect = null;

		if(!logged_in && !window.location.href.endsWith('/login'))
			redirect = <Redirect to='/login' />;

		return redirect;
	}
}

export { Login, Logout, RequireLogin };
