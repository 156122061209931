import React, { Component } from 'react';
import './ChangePassword.css';
import TextField from '@material-ui/core/TextField';

import API from '../classes/API.js';

class ChangePassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			'newPassword': '',
			'errors': []
		}

		this.mounted = false;

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		this.mounted = true;
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	handleChange(e) {
		const name = e.target.name;
		const value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;

		this.setState(prevState => {
			const newState = {...prevState};
			newState[name] = value;
			return newState;
		});
	}

	handleSubmit(e) {
		e.preventDefault();

		this.setState({
			'newPassword': this.state.newPassword.trim(),
			'errors': []
		});

		var api = new API();
		var that = this;
		api.post('api/change_password', { 'new': this.state.newPassword }, d => {
			if(d.status && d.status === 'ok') {
				alert("Password changed successfully");
			} else if(d.status && d.status === 'err' && d.error) {
				that.setState({ 'errors': d.error });
			} else {
				that.setState({ 'errors': 'Unknown error occurred' });
			}
		})
	}

	render() {
		return (
			<div className="changepassword">
				<h2>Change Password</h2>
				<form onSubmit={this.handleSubmit}>
					<div className="error">{this.state.errors}</div>
          <TextField margin="dense" label="Password" variant="filled" name="newPassword" type="password" onChange={this.handleChange} />
					<br />
					<input type="submit" className="btn" value="Submit"/>
				</form>
			</div>
			);
		}
	}

	export default ChangePassword;
