import React, { useEffect, useState, useMemo } from 'react'
import API from '../classes/API.js'
import './TutorReady.css'
import { Button } from '@material-ui/core'
import CountdownTimer from './CountdownTimer'

const TutorReady = () => {

  const [tutorId, setTutorId] = useState()
  const [status, setStatus] = useState({})
  const [intervalActive, setIntervalActive] = useState(true);
  const [activeState, setActiveState] = useState({
      description: '',
      checkinImage: <img alt="" src="/images/modern_icons/Check-in-waiting.svg" />,
  })


  const states = useMemo(() => {
    return {
    ready: {
      description: 'You have checked in! - Thank you, the SAM team have been notified you are ready to teach!',
      checkinButton: false,
      checkinImage: <img alt="" src="/images/modern_icons/Check-in-complete.svg" />,
    },
    due: {
      description:
        'Please check-in! This is required to notify SAM you have checked your internet connection and are ready to teach.',
      checkinButton: true,
      checkinImage: <img alt="" src="/images/modern_icons/Check-in-ready.svg" />,
    },
    notDue: {
      description: <div>Available in <CountdownTimer refreshPeriodSecs={60} targetTimeSecs={status.mktime - 900}/> (15 minutes before your first lesson)</div>,
      checkinButton: false,
      checkinImage: <img alt="" src="/images/modern_icons/Check-in-waiting.svg" />,
    },
    noLessons: {
      description: `You don't have any lessons today`,
      checkinButton: false,
      checkinImage: <img alt="" src="/images/modern_icons/Check-in-waiting.svg" />,
    },
    error: {
      description: `Something went wrong! We could not notify the SAM team that you are ready to teach`,
      checkinButton: false,
      checkinImage: <img alt="" src="/images/modern_icons/Check-in-waiting.svg" />,
    },
    loading: {
      description: `Loading Lessons...`,
      checkinButton: false,
      checkinImage: <img alt="" src="/images/modern_icons/Check-in-waiting.svg" />,
    }
  }
}, [status.mktime]);

  useEffect(
    () => {
      let api = new API()

      const getLatestTutorState = (tutor_id) => {
        api.get(`api/tutorready/${tutor_id}/`, payload => {
          if (payload && payload.status === 'ok') {
            if (payload.body) {
              if (payload.body.tutor_ready_unix) {
                setStatus({ tutor_ready_unix: payload.body.tutor_ready_unix })
              }
              else if (payload.body.mktime) {
                setStatus({ mktime: payload.body.mktime })
              }
            } else {
            setStatus({ noLessons: true})
            }
          }
        })
      }

      let user = JSON.parse(localStorage.getItem('user'))
      if (user && user.tutors) {
        let tutor_id = user.tutors[0]
        setTutorId(tutor_id)
        getLatestTutorState(tutor_id)
        if (intervalActive) {
          const interval = setInterval(() => {
            getLatestTutorState(tutor_id)
          }, 10000);
          return () => clearInterval(interval);
        }
      }
    },
    [intervalActive]
  );
  useEffect(() => {

    if (Object.keys(status).length !== 0) {
      if (status.tutor_ready_unix) {
      setIntervalActive(false)
      setActiveState(states.ready)
      }
      else if (status.mktime && status.mktime - Date.now() / 1000 < 900) setActiveState(states.due)
      else if (status.mktime) setActiveState(states.notDue)
      else setActiveState(states.noLessons)
    } else {
    setActiveState(states.loading)
    }
  }, [status, states])


const submitCheckin = () => {
    let api = new API()
    let tutor_id = JSON.parse(localStorage.getItem('user')).tutors[0]
    api.get(`api/settutorready/${tutor_id}/`, payload => {
      if (payload && payload.status === 'ok') {
        setStatus({ tutor_ready_unix: payload.body.tutor_ready_unix })
      } else {
        setActiveState(states.error)
      }
    })
  }

  const renderCheckinButton = () => {
    if (activeState.checkinButton) {
      return (
        <Button
          variant="contained"
          style={{ backgroundColor: '#FF9900', color: 'white', marginLeft: '10px' }}
          onClick={() => submitCheckin()}>
          Check-in
        </Button>
      )
    }
  }

  return tutorId ? (
  <div className="tutor_ready_container">
    <li className="dash_item clearfix" style={{ color: '#FF9900' }}>
      <div id="img_container_dash_item" className="float_left">
        {activeState.checkinImage}
      </div>
      <div id="content_container_dash_item" className="float_left">
        Lesson check-in:
        {renderCheckinButton()}
        <p>{activeState.description}</p>
      </div>
    </li>
  </div>
  ) : null
}


export default TutorReady
