import React from 'react';
class API {
	constructor() {
		this.debug = true;
		this.token = localStorage.getItem('token');
		this.refresh_token = localStorage.getItem('refresh_token');
		this.client_id = 'mysam';

		this._req_params = null;

		this.loading = false;


		this.apiLocation = process.env.REACT_APP_API_LOC;
	}

	get(loc, fn) {
		this._request(loc, {}, fn, false);
	}

	post(loc, data, fn) {
		this._request(loc, {'method': 'POST', 'body': JSON.stringify(data)}, fn, false);
	}

	_refresh() {
		if(this.refresh_token) {
			this._request('delegate', { 'method': 'POST', 'body': JSON.stringify({refresh_token: this.refresh_token, client_id: this.client_id}) }, d => {
				localStorage.setItem('token', d.token);
				localStorage.setItem('refresh_token', d.refresh_token);
				localStorage.setItem('user', JSON.stringify(d.user));

				this.token = d.token;
				this.refresh_token = d.refresh_token;

				if(this._req_params) {
					this._request(this._req_params.loc, this._req_params.opts, this._req_params.fn, false);
					this._req_params = null;
				}
			}, true);
		} else {
			window.href.location = '/logout';
		}
	}

	_request(loc, opts, fn, r) {
		if(!r) {
			this._req_params = {
				loc: loc,
				opts: opts,
				'fn': fn
			};
		}

		var token = this.token;

		if(!opts['headers'])
			opts['headers'] = {};

		opts['headers']['Authorization'] = `JWT ${token}`;

		if(!opts['headers']['Content-Type'])
			opts['headers']['Content-Type'] = 'application/json';

		if(this.debug) {
			console.log('API:', this.apiLocation + loc);
			console.log('API:', opts);
		}

		fetch(this.apiLocation + loc, opts)
			.then(d => this._checkAuth(d, r))
			.then(res => res.json())
			.then(d => { if(this.debug) {console.log('API:', d);} return d; })
			.then(fn)
			.catch(e => {
				console.error(e);

				if(r) // if the error occurred refreshing the token, the problem will persist until next login
					window.location.href = '/logout';
			});
	}

	_checkAuth(d, r) {
		if(!d.ok && d.status === 401) {
			if(!r) // Don't refresh if already refreshing
				this._refresh()

			throw new Error("Unauthorised"); // End the chain
		}
		return d;
	}

	getSpinner() {
		return (<div className="loader">Loading...</div>);
	}
}

export default API;
