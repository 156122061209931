import React, { Component } from 'react';

class Grades extends Component {

    render() {
        return (
            <div className="textpage">
                <h2>Grades</h2>
                <p>SAM preferred syllabuses – there are others that you are also welcome to use – Please check requirements as some conditions vary (such as number of songs and free choice song options)</p>
                <h3>Trinity Rock and Pop:</h3>
                <a title="Syllabus" href="http://www.trinityrock.com/exams/syllabus" target="_blank" rel="noopener noreferrer">Syllabus</a><br />
                <b>Brighton:</b><br />
                15-16 June (closing 5 May), 22 June (closing 5 May)<br />
                <b>Chichester:</b><br />
                7-8 July 2019 (closing 5 May)<br />
                <h3>Trinity:</h3>
                <b>Theory:</b><br />
                Saturday 2nd November (closing date: 23 September) <br />
                Monday 4th November (closing date: 23 September)<br />
                <br />
                <b>Instrument/Vocal Exams:</b><br />
                <b>Brighton:</b><br />
                Exam dates: 10 - 13 June<br />
                Online booking closes when full or 13 May<br />
                Percussion exams: 8 and 9 June<br />
                Online booking closes when full or 11 May<br />
                <b>Chichester:</b><br />
                TBC<br />
                <b>Eastbourne:</b><br />
                Exam dates (no percussion): 7 June<br />
                Online booking closes when full, or 10th May<br />
                <b>Haywards Heath:</b><br />
                Exam dates: 17-20 June<br />
                Online booking closes when full, or 20th May<br />
                <br />
                <a href="http://www.trinitycollege.co.uk/site/?id=3172" target="_blank" rel="noopener noreferrer">More info</a><br />
                <h3>ABRSM:</h3>
                <a href="https://gb.abrsm.org/en/our-exams/" target="_blank" rel="noopener noreferrer">Syllabus</a><br />
                <h4>SUMMER:</h4>
                <b>All instruments and Grades:</b><br />
17 June - 20th July (Closing date: by Post 3rd May, Online 10th May)<br />
                <b>Jazz Exams:</b><br />
                10 - 29th June (Closing date: by Post 3rd May, Online 10th May)<br />
                <b>Music Theory:</b><br />
                15th June (Closing date: by Post 3rd May, Online 10th May)<br />
                <br />
                <h4>AUTUMN:</h4>
                <b>All instruments and Grades:</b><br />
                4 November - 7 December (Closing date: by Post 20th September, Online 27th September)<br />
                <b>Jazz Exams:</b><br />
                4 - 16 November (Closing date: by Post 20th September, Online 27th September)<br />
                <b>Music Theory:</b><br />
                6th November (Closing date: by Post 20th September, Online 27th September)<br />
                <br />
                <a href="https://gb.abrsm.org/en/exam-booking/exam-dates-and-fees/#" target="_blank" rel="noopener noreferrer">More info</a><br />
                <h3>Rockschool:</h3>
                <a href="https://www.rslawards.com/music/graded-music-exams" target="_blank" rel="noopener noreferrer">Syllabus</a><br />
                <b>Graded Music Exams</b><br />
                7th November - 15th December 2019 (Closing date 23rd October)<br />
                <br />
                <b>Popular Music Theory:</b><br />
                20th July 2019 (Closing date 17th June 2019)<br />
                14th December 2019 (Closing date 11th November 2019)<br />



                <a href="https://www.rslawards.com/music/dates-and-fees" target="_blank" rel="noopener noreferrer">More info</a><br />
                <br />
                FAQs:<br />
                Who enters a student for an exam and how does the process work?<br />
                It is the responsibility of the parents / guardians to enter and pay for exams on request of the tutor. This information will be written by the tutor in the students’ mySAM.<br />
                Will the student need accompaniment and what happens if they do?<br />
                Often for classical instrument and vocal grades an accompanist will be required. Ideally the tutor will accompany the student, if this is not possible (teaching commitments etc) – please contact SAM as soon as possible so the contact details of local accompanists can be provided for the parent / guardian to arrange. If a rehearsal is required and not possible at home, this can be housed at SAM studios in Shoreham-by-Sea.<br />
                Where do they take place?<br />
                There are specific centres across the county, usually within a reasonable travelling distance.<br />
                Does the student needs to complete grade 1 to enter grade 2?<br />
                No, however there will be various scales and techniques covered in the preliminary grade and every grade thereafter that can be used in following grades, therefore must be taught to the student.<br />
                Does the student need a book for grades and how is this incorporated into the lessons?<br />
                Yes, the students’ need a book for every grade, the lessons leading up to the exams will focus on pieces, scales and techniques in preparation. The lessons can also include working on separate “non grade” exercises to vary the learning, with obvious focus more on grades closer to the exam. Please ask parents to purchase the books through mySAM.<br />

            </div>
        );
    }
}

export default Grades;
